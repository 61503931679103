import classNames from "classnames";
import { useState, useMemo, useEffect } from "react";
import { Link } from "react-router-dom";
import { useOrderHistory } from "../../api";
import { ArrowBack, ArrowForward, Button } from "../../components";
import css from "./styles.module.css";
import Status from "./Status";
import AccordionHistory from "./Accordion";
import { IconSend } from "../../components/icons";
import TableRow from "./TableRow";

function OrderHistory() {
  const [page, setPage] = useState(1);
  const [expand, setExpand] = useState<number | null>(null);
  const [width, setWidth] = useState(window.innerWidth);

  const { isLoading, data, isPreviousData } = useOrderHistory(page, 10);

  const availablePages = useMemo(() => {
    const maxPages = data?.data.meta.page.total_pages ?? 0;

    if (page === 1) {
      return Array(Math.min(maxPages, 3))
        .fill(0)
        .map((_, i) => i + 1);
    }

    if (page === maxPages) {
      return Array(3)
        .fill(0)
        .map((_, i) => maxPages - 2 + i);
    }

    return Array(3)
      .fill(0)
      .map((_, i) => page - 1 + i);
  }, [data, page]);

  const onForwardButtonClick = () =>
    setPage((page) =>
      !isPreviousData && data?.data.meta.page.next_page ? page + 1 : page
    );

  const onBackButtonClick = () => setPage((page) => Math.max(page - 1, 1));

  const onPageNumberClick = (num: number) => setPage(num);

  const onExpand = (id: number) => {
    if (id === expand) {
      setExpand(null);
    } else {
      setExpand(id);
    }
  };
  

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const desctopContent = () => {
    return (
      <table className={css.table}>
        <thead>
          <tr className={css.tableHead}>
            <th className={classNames(css.name, css.tableCol)}>СЕГМЕНТ</th>
            <th className={classNames(css.num, css.tableCol)}>ДАТА ПОКУПКИ</th>
            <th className={classNames(css.num, css.tableCol)}>
              СТОИМОСТЬ СЕГМЕНТА
            </th>
            <th className={classNames(css.num, css.tableCol)}>
              ЮЗЕРОВ В АУДИТОРИИ
            </th>
            <th className={classNames(css.num, css.tableCol)}>
              ВРЕМЕНИ НА СТРАНИЦЕ
            </th>
            <th className={classNames(css.num, css.tableCol)}>НОМЕР ЗАКАЗА</th>
            <th className={classNames(css.num, css.tableCol)}>СТАТУС</th>
            <th className={classNames(css.metric, css.tableCol)}>YANDEX ПОЧТА</th>
          </tr>
        </thead>
        <tbody>
          {!isLoading &&
            data?.data.data.map((order) => <TableRow order={order} />)}
        </tbody>
      </table>
    );
  };

  const tabletContent = () => {
    return (
      <div className={css.history}>
        {data?.data.data.map((historyData, i, arr) => {
          const isFirst = i === 0;
          const isExpand = expand && expand === Number(historyData.id);

          return (
            <AccordionHistory
              historyData={historyData}
              isFirst={isFirst}
              isExpand={!!isExpand}
              onExpand={onExpand}
            />
          );
        })}
      </div>
    );
  };

  return (
    <div className={css.container}>
      <h2 className={css.title}>История заказов</h2>

      {
        width < 1440 ? tabletContent() : desctopContent()
      }

      <div className={css.pagination}>
        <Button
          variant="black"
          styles={classNames(css.backButton, css.paginationButton)}
          onClick={onBackButtonClick}
        >
          <ArrowBack className={css.arrowIcon} />
        </Button>
        {availablePages?.map((num) => (
          <Button
            variant="black"
            onClick={() => onPageNumberClick(num)}
            styles={
              num === page
                ? classNames(css.paginationPage, css.selected)
                : css.paginationPage
            }
          >
            {num}
          </Button>
        ))}

        <Button
          variant="black"
          styles={css.paginationButton}
          onClick={onForwardButtonClick}
        >
          <ArrowForward className={css.arrowIcon} />
        </Button>
      </div>
    </div>
  );
}

export default OrderHistory;
