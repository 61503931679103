export const ArrowDown = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_141_1702)">
        <rect
          width="16"
          height="16"
          transform="translate(0.333496)"
          fill="white"
        />
        <path
          d="M1.3335 5L8.3335 12L15.3335 5"
          stroke="black"
          stroke-width="2"
        />
      </g>
      <defs>
        <clipPath id="clip0_141_1702">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.333496)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
