import React, { useEffect } from "react";
import css from "./styles.module.css";
import Button from "../Button/Button";
import { Link, useNavigate } from "react-router-dom";
import { useAppContext } from "../../hooks";

const MobileAppBar = ({
  onClose,
  onCabinetClick,
}: {
  onClose: () => void;
  onCabinetClick: () => void;
}) => {
  const { userData, setUserData } = useAppContext();
  const navigate = useNavigate();

  useEffect(() => {
    document.body.style.overflow = "hidden";
    window.scrollTo(0, 0);

    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  const onLink = (link: string) => {
    navigate(link);
    onClose()
  };

  const logOut = () => {
    localStorage.setItem("userData", "");
    localStorage.setItem("token", "");
    setUserData(null)
    onClose()
  };

  return (
    <div className={css.mobile}>
      <div className={css.mask}></div>
      <div className={css.mobileBar}>
        <div className={css.startElements}>
          <div className={css.btnClose}>
            <Button variant="black" onClick={onClose}>
              <div className={css.separator} style={{ rotate: "45deg" }} />
              <div className={css.separator} style={{ rotate: "135deg" }} />
            </Button>
          </div>

          <div className={css.links}>
            <Link to="/market" className={css.link} onClick={onClose}>
              Маркет аудиторий
            </Link>
            <Link to="/contacts" className={css.link} onClick={onClose}>
              Контакты
            </Link>
          </div>
        </div>
        <div className={css.endElements}>
          {userData ? (
            <div className={css.links}>
              <div className={css.divader} />
              <div onClick={() => onLink("/order_history")} className={css.item}>Мои заказы</div>
              <div onClick={() => onLink("/account_settings")} className={css.item}>Настройки аккаунта</div>
              <div onClick={logOut} className={css.item}>Выйти</div>
            </div>
          ) : (
            <Button onClick={onCabinetClick} variant="black">
              Личный кабинет
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default MobileAppBar;
