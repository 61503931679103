import React from "react";
import css from "./styles.module.css";
import { PersonIcon } from "../../../components";
import { DESCRIPTION_CARD_DATA } from "../../../constants";
import classNames from "classnames";

const Card = (props: {
  title: string;
  content: string;
  background: string;
  variant: string;
}) => {
  const { title, content, background, variant } = props;

  const getCardClassName = () => {
    switch (variant) {
      case "gray":
        return css.grayCard;
      case "slightlyBlue":
        return css.slightlyBlueCard;
      case "blue":
        return css.blueCard;
    }
  };

  return (
    <div className={classNames(css.card, getCardClassName())}>
      <div className={css.iconRow}>
        <div
          className={css.iconContainerFirst}
          style={{ borderColor: background }}
        >
          <PersonIcon fill={background} />
        </div>
        <div
          className={css.iconContainerTwo}
          style={{ borderColor: background }}
        >
          <PersonIcon fill={background} />
        </div>
        <div
          className={css.iconContainerThree}
          style={{ backgroundColor: background }}
        >
          <PersonIcon fill="rgba(255, 255, 255, 1)" />
        </div>
      </div>

      <div className={css.info}>
        <div className={css.title}>{title}</div>
        <div className={css.content}>{content}</div>
      </div>
    </div>
  );
};

const AboutSection = () => {
  return (
    <section className={css.section}>
      <div className={css.description}>Как работает AudienceHub?</div>
      <div className={css.content}>
        <div className={css.about}></div>
        <div className={css.cardsRow}>
          {DESCRIPTION_CARD_DATA.map((data, index) => (
            <Card
              key={`card_${index}`}
              title={data.title}
              content={data.content}
              background={data.background}
              variant={data.variant}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
