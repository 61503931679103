import React from "react";
import css from "./styles.module.css";
import { Section } from "../../../components";
import * as Accordion from "@radix-ui/react-accordion";
import { AccordionContent, AccordionTrigger } from "../../../components/index";
import { ACCORDION_ITEMS } from "../../../constants";

function FaqSection() {
  return (
    <Section title="FAQ" styles={css.wrapper}>
      <Accordion.Root
        className={css.root}
        type="single"
        defaultValue="item-0"
        collapsible
      >
        {ACCORDION_ITEMS.map((item, i) => (
          <Accordion.Item
            key={`accordion_item_${i}`}
            className={css.item}
            value={`item-${i}`}
          >
            <AccordionTrigger className={css.trigger}>
              <p>{item.title}</p>
            </AccordionTrigger>
            <AccordionContent className={css.description}>
              {item.description}
            </AccordionContent>
            {ACCORDION_ITEMS.length - 1 !== i && <hr className={css.divider} />}
          </Accordion.Item>
        ))}
      </Accordion.Root>
    </Section>
  );
}

export default FaqSection;
