import React, { useEffect } from "react";
import css from "./styles.module.css";
import { IconSuccess, IconFailed } from "../icons";
import Button from "../Button/Button";

type TModal = {
  status: string;
  onClose: () => void;
  firstAction: () => void;
  secondAction: () => void;
};

const Modal = (props: TModal) => {
  const { status, onClose, firstAction, secondAction } = props;

  useEffect(() => {
    document.body.style.overflow = "hidden";
    window.scrollTo(0, 0);

    return () => {
      document.body.style.overflow = "";
    };
  }, [])

  return (
    <>
      <div className={css.modalBackground} onClick={onClose}></div>
      <div className={css.modalRoot}>
        <div className={css.modalContainer}>
          <div className={css.icon}>
            {status === "successful" ? <IconSuccess /> : <IconFailed />}
          </div>
          <div className={css.title}>
            {status === "successful"
              ? "ЗАКАЗ УСПЕШНО ОПЛАЧЕН"
              : "ОПЛАТА НЕ ПРОШЛА"}
          </div>

          <div className={css.desc}>
            {status === "successful"
              ? "В течение 15 минут сегмент появится на вашей Yandex Metrika. Дляэтого делать больше ничего не нужно."
              : "Сожалеем, заказ был отменен. Попробуйте еще раз или свяжитесь с поддержкой help@share.ru"}
          </div>

          <div className={css.actions}>
            <Button variant="blue" onClick={firstAction}>
              {status === "successful"
                ? "Вернуться в маркет"
                : "Попробовать снова"}
            </Button>

            <Button variant="white" onClick={secondAction}>
              {status === "successful"
                ? "Перейти в заказы"
                : "Вернуться в маркет"}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
