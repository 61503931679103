type Props = {
  className?: string;
  stroke?: string;
};

function ArrowForward({ className, stroke }: Props) {
  return (
    <svg
      className={className}
      width="17"
      height="29"
      viewBox="0 0 17 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 27L15.5 14.5L1.5 2"
        stroke={stroke || "#1E1E1E"}
        stroke-width="4"
        stroke-linejoin="bevel"
      />
    </svg>
  );
}

export default ArrowForward;
