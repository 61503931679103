import React from "react";
import * as Accordion from "@radix-ui/react-accordion";
import classNames from "classnames";
import styles from "./styles.module.css";
import { ArrowDown } from "./ArrowDown";

export const AccordionTrigger = React.forwardRef(
  ({ children, className, ...props }, forwardedRef) => (
    <Accordion.Header className={styles.Header}>
      <Accordion.Trigger
        className={classNames(styles.Trigger, className)}
        {...props}
        ref={forwardedRef}
      >
        {children}
        <div className={styles.Chevron} aria-hidden>
          <ArrowDown className={styles.ChevronIcon} />
        </div>
      </Accordion.Trigger>
    </Accordion.Header>
  )
);

export const AccordionContent = React.forwardRef(
  ({ children, className, ...props }, forwardedRef) => (
    <Accordion.Content
      className={classNames(styles.Content, className)}
      {...props}
      ref={forwardedRef}
    >
      <div className={styles.ContentText}>{children}</div>
    </Accordion.Content>
  )
);
