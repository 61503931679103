import React, { useState, useEffect, useRef } from "react";
import css from "./styles.module.css";
import first from "./first.jpg";
import second from "./second.jpg";
import third from "./thrid.jpg";
import fourth from "./fourth.jpg";
import * as Progress from "@radix-ui/react-progress";

import classNames from "classnames";

const sliderData = [
  {
    id: 1,
    title: "ТОЧНЫЙ ТАРГЕТИНГ",
    text: "Легко находите и покупайте сегменты, основанные на реальных действиях пользователей. Работайте только с теми, кто уже проявлял интерес к вашей тематике.",
    image: first,
  },
  {
    id: 2,
    title: "ТРЕКИНГ УСПЕХА",
    text: "Отслеживайте эффективность рекламных кампаний с помощью уникальных UTM-меток.",
    image: second,
  },
  {
    id: 3,
    title: "УДОБНАЯ ИНТЕГРАЦИЯ",
    text: "Комфортный простой интерфейс и легкая интеграция с платформами рекламы, такими как Яндекс.Директ, Facebook Ads и другими.",
    image: third,
  },
  {
    id: 4,
    title: "ПОЛНАЯ ЛЕГАЛЬНОСТЬ",
    text: "Мы работаем через API и не обрабатываем личные данные пользователей, что обеспечивает соответствие всем законам о персональных данных.",
    image: fourth,
  },
];

const MarketingSection = () => {
  const [actualData, setActualData] = useState({
    id: 1,
    title: "ТОЧНЫЙ ТАРГЕТИНГ",
    text: "Легко находите и покупайте сегменты, основанные на реальных действиях пользователей. Работайте только с теми, кто уже проявлял интерес к вашей тематике.",
    image: first,
  });
  const [width, setWidth] = useState(window.innerWidth);
  const [isAnimation, setIsAnimation] = useState(true);
  const sectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.outerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const totalTabs = sliderData.length;
    let selectInterval: any;

    if (isAnimation) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      selectInterval = setInterval(() => {
        setActualData((prevTab) => {
          const currentIndex = sliderData.findIndex(
            (tab) => tab.id === prevTab.id
          );
          const nextIndex = (currentIndex + 1) % totalTabs;
          return sliderData[nextIndex];
        });
      }, 4000);
    } else {
      clearInterval(selectInterval);
    }

    return () => clearInterval(selectInterval);
  }, [isAnimation]);

  const onSelectSlider = (data: any) => {
    setActualData(data);
    setIsAnimation(false);
  };

  const Divader = ({
    isActive,
    isAnimation,
  }: {
    isActive: boolean;
    isAnimation: boolean;
  }) => {
    const [progress, setProgress] = React.useState(0);

    React.useEffect(() => {
      let timer: any;

      if (isAnimation) {
        timer = setTimeout(() => setProgress(100), 2000);
      } else {
        timer = setTimeout(() => setProgress(100), 0);
      }

      return () => clearTimeout(timer);
    }, []);

    return isActive ? (
      <Progress.Root className={css.Root} value={progress}>
        <Progress.Indicator
          className={css.Indicator}
          style={{ transform: `translateX(-${100 - progress}%)` }}
        />
      </Progress.Root>
    ) : (
      <div
        style={{ height: "1px", backgroundColor: "gray", width: "100%" }}
      ></div>
    );
  };

  const rederDesctopSlider = () => {
    return (
      <div className={css.content}>
        <div className={css.title}></div>
        <div className={css.slider}>
          <div className={css.headers}>
            {sliderData.map((el, i) => (
              <div
                onClick={() => onSelectSlider(el)}
                className={classNames(
                  css.sliderArticle,
                  el.id === actualData.id && css.sliderArticleActive
                )}
                key={i}
              >
                <Divader
                  isActive={el.id === actualData.id}
                  isAnimation={isAnimation}
                />
                <div
                  className={classNames(
                    css.sliderTitle,
                    el.id === actualData.id && css.sliderActiveTitle
                  )}
                >
                  {el.title}
                </div>
                {el.id === actualData.id && (
                  <div className={css.sliderText}>{el.text}</div>
                )}
              </div>
            ))}
          </div>
          <div className={css.image}>
            <img src={actualData.image} alt="" />
          </div>
        </div>
      </div>
    );
  };

  const renderMobileSlider = () => {
    return sliderData.map((el, i) => (
      <div key={i} className={css.mobileSlider}>
        <div className={css.image}>
          <img src={el.image} alt="" />
        </div>
        <div className={css.desc}>
          <div className={css.title}>{el.title}</div>
          <div className={css.text}>{el.text}</div>
        </div>
      </div>
    ));
  };

  return (
    <section ref={sectionRef} className={css.section}>
      <div className={css.description}>Выгоды для рекламодателей</div>
      {width >= 712 ? rederDesctopSlider() : renderMobileSlider()}
    </section>
  );
};

export default MarketingSection;
