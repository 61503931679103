type Props = {
  className?: string;
};

function ArrowBack({ className }: Props) {
  return (
    <svg
      className={className}
      width="17"
      height="29"
      viewBox="0 0 17 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5 2L1.5 14.5L15.5 27"
        stroke="#1E1E1E"
        stroke-width="4"
        stroke-linejoin="bevel"
      />
    </svg>
  );
}

export default ArrowBack;
