import React, { Fragment, useEffect, useState } from "react";
import css from "./styles.module.css";
import classNames from "classnames";
import { Order } from "../../api/types";
import { Link } from "react-router-dom";
import Status from "./Status";
import { ArrowForward } from "../../components";
import { IconSend } from "../../components/icons";
import { postYandexMail } from "../../api";

type TAccodion = {
  historyData: Order;
  onExpand: (id: number) => void;
  isFirst: boolean;
  isExpand: boolean;
};

const AccordionHistory = ({
  historyData,
  onExpand,
  isFirst,
  isExpand,
}: TAccodion) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [metricID, setMetricID] = useState<string>("");


  const onSendYandexMail = async () => {
    await postYandexMail(historyData.id, metricID).then((res) => console.log('res:', res)).catch((e) => console.log('e:', e))
  }

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    setMetricID(historyData.yandex_segment_id);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getTabletContent = () => {
    const isMobile = width < 768;

    return (
      <div className={css.accordionContent}>
        <div className={css.divader} />
        <div className={classNames(css.row, isMobile && css.rowMobile)}>
          <div
            className={css.cell}
            style={!isMobile ? { minWidth: "192px" } : { minWidth: "148px" }}
          >
            <div className={css.cellTitle}>СТОИМОСТЬ СЕГМЕНТА</div>
            <div className={css.cellValue}>{historyData.price}</div>
          </div>
          <div
            className={css.cell}
            style={
              isMobile
                ? { minWidth: "148px", marginLeft: "20px" }
                : { minWidth: "192px", marginLeft: "20px" }
            }
          >
            <div className={css.cellTitle}>ЮЗЕРОВ В АУДИТОРИИ</div>
            <div className={css.cellValue}>
              {historyData.users_count || "-"}
            </div>
          </div>
          {!isMobile && (
            <div
              className={css.cell}
              style={{ minWidth: "192px", marginLeft: "20px" }}
            >
              <div className={css.cellTitle}>ВРЕМЕНИ НА СТРАНИЦЕ</div>
              <div className={css.cellValue}>{historyData.time_spent}</div>
            </div>
          )}
        </div>
        <div className={css.divader} />
        <div className={classNames(css.row, isMobile && css.rowMobile)}>
          {isMobile ? (
            <>
              <div className={css.cell} style={{ minWidth: "148px" }}>
                <div className={css.cellTitle}>ВРЕМЕНИ НА СТРАНИЦЕ</div>
                <div className={css.cellValue}>{historyData.time_spent}</div>
              </div>
              <div className={css.cell} style={{ marginLeft: "20px" }}>
                <div className={css.cellTitle}>ДАТА ПОКУПКИ</div>
                <div className={css.cellValue}>
                  {historyData.date_of_purchase}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className={css.cell} style={{ minWidth: "192px" }}>
                <div className={css.cellTitle}>ДАТА ПОКУПКИ</div>
                <div className={css.cellValue}>
                  {historyData.date_of_purchase}
                </div>
              </div>
              <div
                className={css.cell}
                style={{ minWidth: "192px", marginLeft: "20px" }}
              >
                <div className={css.cellTitle}>НОМЕР ЗАКАЗА</div>
                <div className={css.cellValue}>
                  {historyData.order_number || "-"}
                </div>
              </div>
              <div
                className={css.cell}
                style={{ minWidth: "192px", marginLeft: "20px" }}
              >
                <div className={css.cellTitle}>YANDEX ПОЧТА</div>
                <div
                  className={css.cellValue}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    value={metricID}
                    id="1"
                    onChange={(e) => setMetricID(e.target.value)}
                  />
                  <div className={css.iconSend} onClick={onSendYandexMail}>
                    <IconSend />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        {isMobile && (
          <>
            <div className={css.divader} />

            <div className={classNames(css.row, isMobile && css.rowMobile)}>
              <div className={css.cell} style={{ minWidth: "148px" }}>
                <div className={css.cellTitle}>НОМЕР ЗАКАЗА</div>
                <div className={css.cellValue}>
                  {historyData.order_number || "-"}
                </div>
              </div>
              <div className={css.cell} style={{ marginLeft: "20px" }}>
                <div className={css.cellTitle}>YANDEX ПОЧТА</div>
                <div
                  className={css.cellValue}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input
                    value={metricID}
                    id="1"
                    onChange={(e) => setMetricID(e.target.value)}
                  />
                  <div className={css.iconSend} onClick={onSendYandexMail}>
                    <IconSend />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <Fragment>
      <div
        onClick={() => onExpand(Number(historyData.id))}
        className={classNames(
          css.historyAccordion,
          !isFirst && css.accordionDivader
        )}
        key={historyData.id}
      >
        <div className={css.accordionTitles}>
          <div
            className={css.cell}
            style={{ minWidth: "30vw", maxWidth: "30vw" }}
          >
            <div className={css.cellTitle}>СЕГМЕНТ</div>
            <div className={css.cellValue}>
              <Link to="">{historyData.segment_name}</Link>
            </div>
          </div>
          <div className={css.cell}>
            <div className={css.cellTitle}>СТАТУС</div>
            <div className={css.cellValue}>
              <Status content={historyData.status} />
            </div>
          </div>
        </div>
        <ArrowForward className={isExpand ? css.opened : css.closed} />
      </div>

      {isExpand && getTabletContent()}
    </Fragment>
  );
};

export default AccordionHistory;
