import React from "react";

const IconSuccess = () => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="64" height="64" rx="8" fill="#20DF39" />
      <path d="M18.5 37.5L30.5 45.5L49 17" stroke="white" stroke-width="4" />
    </svg>
  );
};

export default IconSuccess;
