import React, { useEffect, useState } from "react";
import css from "./styles.module.css";
import Input from "../Input/Input";
import Button from "../Button/Button";
import { useAppContext } from "../../hooks";
import { postDemoQuery } from "../../api";

const ModalQuery = () => {
  const [name, setName] = useState<string>('')
  const [mail, setMail] = useState<string>('')
  const [phone, setPhone] = useState<string>('')

  const { isShowDemo, setIsShowDemo } = useAppContext();

  useEffect(() => {
    if (isShowDemo) {
      document.body.style.overflow = "hidden";
      window.scrollTo(0, 0);
    } else {
      document.body.style.overflow = "";
    }
  }, [isShowDemo]);
  const onSubmit = async () => {
    await postDemoQuery(name, mail, phone)
    setIsShowDemo(false)
  };

  return isShowDemo ? (
    <>
      <div
        className={css.modalBackground}
        onClick={() => setIsShowDemo(false)}
      ></div>
      <div className={css.modalRoot}>
        <div className={css.modalContainer}>
          <>
            <p className={css.authBigText}>ЗАЯВКА НА ДЕМО</p>
            <div className={css.form}>
              <Input
                id="name"
                label="Имя"
                value={name}
                onChange={(value) => setName(value)}
              />
              <Input
                id="mail"
                label="Почта"
                value={mail}
                onChange={(value) => setMail(value)}
              />
              <Input
                id="phone"
                label="Телефон"
                value={phone}
                onChange={(value) => setPhone(value)}
              />
              <Button variant="blue" onClick={onSubmit}>
                Отправить
              </Button>
            </div>
          </>
        </div>
      </div>
    </>
  ) : null;
};

export default ModalQuery;
