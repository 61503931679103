import React from "react";

type TIcon = {
    fill?: string
}


const PersonIcon = ({ fill }: TIcon) => {
  return (
    <svg
      width="21"
      height="27"
      viewBox="0 0 21 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9258 3.125C13.9258 4.8509 12.5267 6.25 10.8008 6.25C9.07488 6.25 7.67578 4.8509 7.67578 3.125C7.67578 1.3991 9.07488 0 10.8008 0C12.5267 0 13.9258 1.3991 13.9258 3.125ZM20.8008 13.75H14.3483L18.8125 26.25H13.5033L10.8008 18.683L8.09831 26.25H2.78898L7.25328 13.75H0.800781V8.75H20.8008V13.75Z"
        fill={fill || "rgba(200, 200, 200, 1)"}
      />
    </svg>
  );
};

export default PersonIcon;
