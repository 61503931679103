/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef } from "react";
import css from "./styles.module.css";
import classNames from "classnames";

type TSquare = {
  squareId: number;
  top: number;
  left: number;
  rowId?: number;
  rowMinWidth?: string;
};

type TSquareRow = {
  id: number;
  count: number;
  startCoord: {
    top: number;
    left: number;
    bias: number;
  };
  rowMargin: number;
  rowMinWidth: string;
};

type TImageOverlay = {
  img: string;
  isFull: boolean;
  squaresRows: TSquareRow[];
  squareJustifyContent: string;
  showImageAnyWay: boolean;
  marginLeft?: boolean;
  wrapperStyles?: React.CSSProperties;
  startAnimation: boolean;
};

const Square = ({
  square,
  img,
  showAnyWay,
  startAnimation,
}: {
  square: TSquare;
  img: string;
  showAnyWay: boolean;
  startAnimation: boolean;
}) => {
  const squareRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (showAnyWay && squareRef.current) {
      squareRef.current.style.backgroundImage = `url(${img})`;
      squareRef.current.style.backgroundPosition = `-${square.left}px -${square.top}px`;
    }
  }, [showAnyWay, img, square.left, square.top]);

  useEffect(() => {
    if (squareRef.current && !showAnyWay) {
      squareRef.current.style.backgroundImage = "";
      squareRef.current.style.backgroundPosition = "";
    }
  }, [showAnyWay]);

  useEffect(() => {
    if (startAnimation && showAnyWay && squareRef.current) {
      squareRef.current.style.transition = `opacity ${square.squareId}s`;
      squareRef.current.style.opacity = "1";
    }
  }, [showAnyWay, startAnimation]);

  const onHover = () => {
    if (squareRef.current && !showAnyWay) {
      squareRef.current.style.backgroundImage = `url(${img})`;
      squareRef.current.style.backgroundPosition = `-${square.left}px -${square.top}px`;
      squareRef.current.style.cursor = "pointer";
      squareRef.current.style.opacity = "1";
      squareRef.current.style.transition = "none";
    }
  };

  const onLeave = () => {
    if (squareRef.current && !showAnyWay) {
      squareRef.current.style.transition = "opacity 2.5s ease-in-out";
      squareRef.current.style.opacity = "0";
    }

    setTimeout(() => {
      if (squareRef.current && !showAnyWay) {
        squareRef.current.style.backgroundImage = "";
        squareRef.current.style.backgroundPosition = "";
        squareRef.current.style.opacity = "1";
      }
    }, 1500);
  };

  return (
    <div className={css.squareWrapper}>
      <div
        ref={squareRef}
        className={classNames(
          css.square,
          showAnyWay ? css.mobileSquare : css.desctopSquare
        )}
        onMouseEnter={onHover}
        onMouseLeave={onLeave}
      ></div>
    </div>
  );
};

const ImageOverlay = (props: TImageOverlay) => {
  const {
    showImageAnyWay,
    img,
    squaresRows,
    squareJustifyContent,
    marginLeft,
    wrapperStyles,
    startAnimation,
  } = props;

  const renderData = useMemo(() => {
    return squaresRows.map((row) => {
      const squares: TSquare[] = [];

      for (let i = 1; i < row.count; i++) {
        let left = 0;

        if (i === 1) {
          left = row.startCoord.left;
        } else {
          const prevValue = squares.find((el) => el.squareId === i - 1);
          left = (prevValue?.left as number) + row.startCoord.bias;
        }

        squares.push({
          squareId: i,
          top: row.startCoord.top,
          left,
          rowMinWidth: row.rowMinWidth,
        });
      }

      const newrow: {
        id: number;
        squares: TSquare[];
        rowMargin: number;
        rowMinWidth: string;
      } = {
        id: row.id,
        rowMargin: row.rowMargin,
        squares: squares,
        rowMinWidth: row.rowMinWidth,
      };

      return newrow;
    });
  }, [squaresRows]);

  return (
    <div className={css.wrapper} style={wrapperStyles}>
      {renderData.map((row) => (
        <div
          key={`row${row.id}`}
          style={{
            display: "flex",
            justifyContent: squareJustifyContent,
            marginRight: marginLeft ? 0 : row.rowMargin,
            marginLeft: marginLeft ? row.rowMargin : 0,
            minWidth: row.rowMinWidth,
          }}
        >
          {row.squares.map((square) => (
            <Square
              key={`row${row.id}square${square.squareId}`}
              square={square}
              img={img}
              showAnyWay={showImageAnyWay}
              startAnimation={startAnimation}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

export default ImageOverlay;
