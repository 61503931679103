/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import css from "./styles.module.css";

const Contacts = () => {
  return (
    <section className={css.section}>
      <div className={css.description}>Контакты</div>

      <div className={css.about}>
        <div className={css.title}>СВЯЗЬ</div>
        <a href="mailto:info@audiencehub.ru" className={css.link}>
          info@audiencehub.ru
        </a>
        {/* временно */}
        {/* <a className={css.link}>+7 999 876-54-32</a> */}
      </div>

      {/* временно */}

      {/* <div className={css.about}>
        <div className={css.title}>СОЦСЕТИ</div>
        <a className={css.link}>Телеграм</a>
        <a className={css.link}>Запретнограм</a>
        <a className={css.link}>Вконтакте</a>
      </div> */}

      <div className={css.about}>
        <div className={css.title}>КОМПАНИЯ</div>
        <div className={css.subtitle}>
          195027, Санкт-Петербург, Магнитогорская ул, 23к1 лит А, помещ. 18н
          офис 231-3
        </div>
        <div className={css.subtitle}>ООО  «Дофамин» ИНН: 7806586136</div>
      </div>
    </section>
  );
};

export default Contacts;
