import React, { createContext, useEffect, useState } from "react";
import { UserProfile } from "../api";
import { TMarketItemWithPrice } from "../types";

export const AppContext = createContext<{
  orderData: TMarketItemWithPrice | null;
  setOrderData: (item: TMarketItemWithPrice) => null;
  userData: UserProfile | null;
  setUserData: (data: UserProfile | null) => void;
  authOpen: boolean;
  setAuthOpen: (isOpen: boolean) => null;
  isShowDemo: boolean;
  setIsShowDemo: (isShow: boolean) => null;
}>({
  //default value is required now for react context
  orderData: null,
  setOrderData: (item: TMarketItemWithPrice) => null,
  userData: null,
  setUserData: (data) => {},
  authOpen: false,
  setAuthOpen: (isOpen: boolean) => null,
  isShowDemo: false,
  setIsShowDemo: (isShow: boolean) => null
});

export default function AppProvider(props: any) {
  const [orderData, setOrderData] = useState<TMarketItemWithPrice | null>(null);
  const [userData, setUserData] = useState<UserProfile | null>(null);
  const [authOpen, setAuthOpen] = useState(false);
  const [isShowDemo, setIsShowDemo] = useState(false)

  const checkAuth = () => {
    if (localStorage.getItem("userData")) {
      setUserData(JSON.parse(localStorage.getItem("userData") as string));
    }
  };

  useEffect(() => {
    checkAuth();
  }, []);

  const updateUserData = (userData: UserProfile | null) => {
    setUserData(userData);
    localStorage.setItem("userData", JSON.stringify(userData));
  };

  return (
    <AppContext.Provider
      value={{
        orderData,
        setOrderData,
        userData,
        setUserData: updateUserData,
        authOpen,
        setAuthOpen,
        isShowDemo,
        setIsShowDemo
      }}
      {...props}
    />
  );
}
