import React, { useState, useEffect } from "react";
import css from "./styles.module.css";
import { Link, useNavigate } from "react-router-dom";
import Button from "../Button/Button";
import MobileAppBar from "./Mobile";
import { createPortal } from "react-dom";
import Auth from "../Auth/Auth";
import { useAppContext } from "../../hooks";
import { ArrowForward, Logo } from "../icons";
import classNames from "classnames";

const AppBar = () => {
  const [barOpen, setBarOpen] = useState(false);
  const { userData, setUserData, authOpen, setAuthOpen } = useAppContext();
  const navigate = useNavigate();

  const onCabinetClick = () => {
    setAuthOpen(true);
  };

  useEffect(() => {
    if (authOpen) {
      document.body.style.overflow = "hidden";
      window.scrollTo(0, 0);
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [authOpen]);

  const DropDown = () => {
    const [open, setOpen] = useState(false);

    const onOpen = () => {
      setOpen(!open);
    };

    const onLink = (link: string) => {
      navigate(link);
    };

    const logOut = () => {
      localStorage.setItem("userData", "");
      localStorage.setItem("token", "");
      setUserData(null);
    };

    return (
      <div
        onClick={onOpen}
        style={{ cursor: "pointer" }}
        className={classNames(
          css.selectContainer,
          open && css.selectContainerActive
        )}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className={css.label}>
            {(userData?.first_name ?? "") + (userData?.last_name ?? "") ||
              "user_name"}
          </div>
          <div
            className={classNames(css.icon, open ? css.iconUp : css.iconDown)}
          >
            <ArrowForward />
          </div>
        </div>
        {open && (
          <div className={css.dropDown}>
            <div onClick={() => onLink("/order_history")} className={css.item}>
              Мои заказы
            </div>
            <div
              onClick={() => onLink("/account_settings")}
              className={css.item}
            >
              Настройка аккаунта
            </div>
            <div onClick={logOut} className={css.item}>
              Выйти
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <div style={{ position: "relative" }}>
        <div className={css.navBar}>
          <div className={css.navBarStart}>
            <Link to="./" className={css.logo}>
              <Logo />
              <span style={{ marginLeft: "5.5px" }}>
                AUDIENCE <br /> HUB
              </span>
            </Link>
          </div>
          <div className={css.navBarEnd}>
            <Link to="/market" className={css.link}>
              Маркет аудиторий
            </Link>
            <Link to="/contacts" className={css.link}>
              Контакты
            </Link>
            {userData ? (
              <DropDown />
            ) : (
              <Button
                styles={css.actionBtn}
                variant="black"
                onClick={onCabinetClick}
              >
                Личный кабинет
              </Button>
            )}

            <Button
              styles={css.burgerBtn}
              variant="black"
              onClick={() => setBarOpen(true)}
            >
              <div className={css.separator} />
              <div className={css.separator} style={{ marginTop: "9px" }} />
              <div className={css.separator} style={{ marginTop: "9px" }} />
            </Button>
          </div>
        </div>
        {barOpen && (
          <MobileAppBar
            onCabinetClick={onCabinetClick}
            onClose={() => setBarOpen(false)}
          />
        )}
      </div>
      {authOpen &&
        createPortal(<Auth setAuthOpen={setAuthOpen} />, document.body)}
    </>
  );
};

export default AppBar;
