import React from "react";

const IconFilter = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" transform="translate(0.5)" fill="none" />
      <path d="M1.5 9L15.5 9" stroke="#2155FE" stroke-width="2" />
      <path d="M1.5 14L15.5 14" stroke="#2155FE" stroke-width="2" />
      <path d="M1.5 4H15.5" stroke="#2155FE" stroke-width="2" />
      <rect x="3.5" y="11" width="3" height="3" fill="#2155FE" />
      <rect x="10.5" y="6" width="3" height="3" fill="#2155FE" />
      <rect x="5.5" y="1" width="3" height="3" fill="#2155FE" />
    </svg>
  );
};

export default IconFilter;
