import React from "react";

const IconFailed = () => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="64" height="64" rx="8" fill="#F95914" />
      <path d="M18 18L46 46" stroke="white" stroke-width="4" />
      <path d="M18 46L45 18" stroke="white" stroke-width="4" />
    </svg>
  );
};

export default IconFailed;
