import React, { useEffect, useState } from "react";
import {
  AboutSection,
  ExamplesSection,
  FaqSection,
  LeadSection,
  MarketingSection,
  MozaicSection,
} from ".";
import { useLocation, useNavigate } from "react-router-dom";
import Modal from "../../components/Modal/ModalPostOrder";

const Main = () => {
  const [modalStatus, setModalStatus] = useState<string | null>(null);

  const location = useLocation();
  const navigate = useNavigate();

  const onFollowLink = (link: string) => {
    navigate(link);
  };

  useEffect(() => {
    if (location.pathname === "/successful_payment") {
      setModalStatus("successful")
    } else if (location.pathname === "/failed_payment") {
      setModalStatus("failed")
    }

  }, [location.pathname]);

  const onCloseModal = () => {
    setModalStatus(null)
  }

  const onFirstModalAction = () => {
    if (modalStatus === "successful") {
      onFollowLink("/market")
    } else if (modalStatus === "failed") {

    }
    onCloseModal()
  }

  const onSecondModalAction = () => {
    if (modalStatus === "successful") {
      onFollowLink("/order_history")
    } else if (modalStatus === "failed") {
      onFollowLink("/market")
    }
    onCloseModal()
  }

  return (
    <>
      <MozaicSection />
      <AboutSection />
      <MarketingSection />
      <ExamplesSection />
      <FaqSection />
      <LeadSection />
      {modalStatus && <Modal status={modalStatus} onClose={onCloseModal} firstAction={onFirstModalAction} secondAction={onSecondModalAction} />}
    </>
  );
};

export default Main;
