import React, { useEffect, useState } from "react";
import css from "./styles.module.css";
import classNames from "classnames";
import { Order } from "../../api/types";
import { Link } from "react-router-dom";
import Status from "./Status";
import { IconSend } from "../../components/icons";
import { postYandexMail } from "../../api";

type TTableRow = {
  order: Order;
};

const TableRow = ({ order }: TTableRow) => {
  const [metricID, setMetricID] = useState<string>("");

  useEffect(() => {
    setMetricID(order.yandex_segment_id);
  }, [order]);

  const onSendYandexMail = async () => {
    await postYandexMail(order.id, metricID).then((res) => console.log('res:', res)).catch((e) => console.log('e:', e))
  }

  return (
    <tr className={css.tableRow} key={order.id}>
      <th className={classNames(css.firstCell, css.tableCell, css.name)}>
        <Link to="">{order.segment_name}</Link>
      </th>
      <th className={classNames(css.numCell, css.num)}>{order.created_at}</th>
      <th
        className={classNames(css.numCell, css.num)}
      >{`${order.price} руб`}</th>
      <th className={classNames(css.numCell, css.num)}>{order.users_count}</th>
      <th className={classNames(css.numCell, css.num)}>{order.time_spent}</th>
      <th className={classNames(css.numCell, css.num)}>{order.order_number}</th>
      <th className={classNames(css.statusCell, css.num)}>
        <Status content={order.status} />
      </th>
      <th className={classNames(css.tableCell, css.lastCell, css.metric)}>
        <div
          className={css.cellValue}
          style={{ display: "flex", alignItems: "center" }}
        >
          <input
            value={metricID}
            id="1"
            onChange={(e) => setMetricID(e.target.value)}
          />
          <div className={css.iconSend} onClick={onSendYandexMail}>
            <IconSend />
          </div>
        </div>
      </th>
    </tr>
  );
};

export default TableRow;
