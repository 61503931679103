function Logo() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_759_10)">
        <rect width="48" height="48" rx="14.7692" fill="#1E1E1E" />
        <path
          d="M5.45278 40.79L18.4469 7.34924H24.2513L37.5798 40.79H31.5366L28.4553 32.812H14.2907L11.3288 40.79H5.45278ZM15.6761 28.202H27.0221L21.2416 13.6313L15.6761 28.202Z"
          fill="white"
        />
        <path
          d="M32.5875 40.79V7.34924H37.9381V20.63H54.133V7.34924H59.4835V40.79H54.133V25.5506H37.9381V40.79H32.5875Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_759_10">
          <rect width="48" height="48" rx="14.7692" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Logo;
