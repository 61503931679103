import classNames from "classnames";
import React from "react";
import css from "./styles.module.css"

const STATUS_MAP: Record<string, string> = {
    ВЫПОЛНЕН: "#20DF39",
    ОТМЕНЕН: "#F95914",
  };

const Status = ({ content }: { content: string }) => {
  return (
    <div className={classNames(css.statusBox)}>
      <svg
        width="8"
        height="8"
        viewBox="0 0 8 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="4" cy="4" r="4" fill={STATUS_MAP[content]} />
      </svg>
      <p>{content}</p>
    </div>
  );
};

export default Status;
