import React, { PropsWithChildren } from "react";
import css from "./styles.module.css";
import classNames from "classnames";

type PropsWithoutDescription = {
  title: string;
  styles?: string;
};

type PropsWithDescription = {
  title: string;
  marginDescription: string;
  description: string;
  styles?: string;
  descriptionStyles?: React.CSSProperties;
};

type Props = (PropsWithDescription | PropsWithoutDescription) &
  PropsWithChildren;

function Section(props: Props) {
  if ("description" in props) {
    const {
      title,
      description,
      children,
      marginDescription,
      styles,
      descriptionStyles,
    } = props;

    return (
      <section className={classNames(css.sectionContainer, styles)}>
        <div className={css.sectionTitle}>{title}</div>
        <div
          className={css.descriptionBlock}
          style={{ ...descriptionStyles, marginTop: marginDescription }}
        >
          <h3 className={css.description}>{description}</h3>
          {children}
        </div>
      </section>
    );
  }

  const { title, children, styles } = props;

  return (
    <section className={classNames(css.sectionContainer, styles)}>
      <div className={css.sectionTitle}>{title}</div>
      {children}
    </section>
  );
}

export default Section;
