import css from "./styles.module.css";
import classNames from "classnames";

type Props = {
  id: string;
  value: string;
  label: string;
  type?: string;
  styles?: string;
  onChange: (value: string) => void;
};

function Input({ id, value, label, type, styles, onChange }: Props) {
  return (
    <div className={css.container}>
      <label className={css.label} htmlFor={id}>
        {label}
      </label>
      <input
        className={classNames(css.input, styles)}
        type={type}
        name={id}
        value={value}
        onChange={(event) => onChange(event.target.value)}
      />
    </div>
  );
}

export default Input;
