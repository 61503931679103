type Props = {
  className?: string;
};

export const ArrowDown = ({ className }: Props) => {
  return (
    <svg
      className={className}
      width="28"
      height="17"
      viewBox="0 0 28 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 1.5L14 15.5L26.5 1.5"
        stroke-width="4"
        stroke-linejoin="bevel"
      />
    </svg>
  );
};
