import { useQuery } from "@tanstack/react-query";
import { getCategories, getOrderHistory, getSegments } from "./api";
import { MarketFilter } from "./types";

export const useSegments = (
  page: number,
  perPage: number,
  filter?: MarketFilter,
) => {
  return useQuery({
    queryKey: ["segments", page, perPage],
    queryFn: () => getSegments(page, perPage, filter),
    keepPreviousData: true,
  });
};

export const useOrderHistory = (page: number, perPage: number) => {
  return useQuery({
    queryKey: ["order_history", page, perPage],
    queryFn: () => getOrderHistory(page, perPage),
    keepPreviousData: true,
  });
};

export const useCategories = () => {
  return useQuery({
    queryKey: ["categories"],
    queryFn: () => getCategories(),
  });
};